import React, { useEffect } from 'react';

// Component imports
import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import { m as motion, useCycle, AnimatePresence } from 'framer-motion';
import { Section, Text } from '@utils';

// Components
const TabletNavLink: React.FC<{ href: string }> = ({ href, children }) => (
  <li>
    <Link
      to={href}
      className="hover:text-coral focus:text-coral text-lg font-semibold transition duration-200 ease-out"
    >
      {children}
    </Link>
  </li>
);

const Header: React.FC = () => {
  // State
  const [navbar, toggleNavbar] = useCycle('closed', 'opened');

  // Helpers
  useEffect(() => {
    if (navbar === 'opened') {
      document.querySelector('body')?.classList.add('overflow-hidden');
    } else {
      document.querySelector('body')?.classList.remove('overflow-hidden');
    }
  }, [navbar]);

  // Components
  const MobileNavLink: React.FC<{ href: string; number: number }> = ({
    href,
    children,
    number,
  }) => (
    <motion.li
      transition={{
        type: 'spring',
        duration: 0.5,
        stiffness: 50,
        mass: 0.5,
      }}
      variants={{
        opened: { opacity: 1, x: 0 },
        closed: { opacity: 0, x: -32 },
      }}
    >
      <Link
        to={href}
        className="hover:text-coral focus:outline-none ease-outQuint focus:text-coral md:text-6xl inline-flex items-center space-x-4 text-4xl font-semibold transition duration-500"
        onClick={() => toggleNavbar()}
      >
        <Text style="h1" tag="p">
          {children}
        </Text>
      </Link>
    </motion.li>
  );

  return (
    <header className="md:h-24 sticky inset-x-0 top-0 z-50 h-16 bg-gray-900">
      <nav className="sm:px-6 md:px-8 container flex items-center justify-between h-full px-4 mx-auto">
        <div className="relative z-50">
          <Link
            to="/"
            onClick={() => {
              if (navbar === 'opened') {
                toggleNavbar();
              }
            }}
          >
            <StaticImage
              src="../../assets/img/glyph.png"
              alt="CoralDev"
              layout="fixed"
              objectFit="contain"
              height={48}
              width={48}
              placeholder="tracedSVG"
            />
          </Link>
        </div>
        <div className="md:hidden relative z-50">
          <motion.button
            onClick={() => toggleNavbar()}
            className="flex flex-col focus:outline-none hover:text-coral focus:text-coral items-end justify-center w-8 h-8 space-y-[5px] overflow-hidden"
            animate={navbar}
          >
            <span className="sr-only">Menu</span>
            <motion.div
              transition={{
                type: 'spring',
                duration: 1,
                stiffness: 50,
                mass: 0.5,
              }}
              variants={{
                opened: {
                  y: 7,
                  rotate: 45,
                },
                closed: {
                  y: 0,
                  rotate: 0,
                },
              }}
              className="inline-block origin-center h-[2px] rounded-full bg-current w-6"
            ></motion.div>
            <motion.div
              transition={{
                type: 'spring',
                duration: 1,
                stiffness: 50,
                mass: 0.5,
              }}
              variants={{
                opened: {
                  scale: 0.85,
                  opacity: 0,
                },
                closed: {
                  scale: 1,
                  opacity: 1,
                },
              }}
              className="inline-block h-[2px] rounded-full bg-current w-4"
            ></motion.div>
            <motion.div
              transition={{
                type: 'spring',
                duration: 1,
                stiffness: 50,
                mass: 0.5,
              }}
              variants={{
                opened: {
                  y: -7,
                  rotate: -45,
                },
                closed: {
                  y: 0,
                  rotate: 0,
                },
              }}
              className="inline-block origin-center h-[2px] rounded-full bg-current w-6"
            ></motion.div>
          </motion.button>
        </div>
        <AnimatePresence>
          {navbar === 'opened' && (
            <motion.div
              key="navbar"
              className="md:hidden fixed inset-0 z-40 bg-gray-900"
              animate="opened"
              exit="closed"
              initial="closed"
              transition={{
                type: 'spring',
                duration: 0.5,
                stiffness: 50,
                mass: 0.5,
              }}
              variants={{
                opened: {
                  opacity: 1,
                  transition: {
                    delayChildren: 0.5,
                    staggerChildren: 0.2,
                  },
                },
                closed: {
                  opacity: 0,
                },
              }}
            >
              <Section id="nav" className="flex flex-col flex-1 h-full">
                <ul className="md:space-y-10 lg:space-y-16 md:items-center flex flex-col justify-center flex-1 space-y-5">
                  <MobileNavLink href="/" number={1}>
                    Home
                  </MobileNavLink>
                  <MobileNavLink href="/team" number={2}>
                    Our Team
                  </MobileNavLink>
                  <MobileNavLink href="/work" number={3}>
                    Our Work
                  </MobileNavLink>
                  <MobileNavLink href="/blog" number={3}>
                    Blog
                  </MobileNavLink>
                  <MobileNavLink href="/contact" number={4}>
                    Contact
                  </MobileNavLink>
                </ul>
              </Section>
            </motion.div>
          )}
        </AnimatePresence>

        {/* Tablet navbar */}
        <div className="md:block hidden">
          <ul className="xl:space-x-20 flex items-center space-x-12">
            <TabletNavLink href="/">Home</TabletNavLink>
            <TabletNavLink href="/team">Our Team</TabletNavLink>
            <TabletNavLink href="/work">Our Work</TabletNavLink>
            <TabletNavLink href="/blog">Blog</TabletNavLink>
            <TabletNavLink href="/contact">Contact</TabletNavLink>
          </ul>
        </div>
      </nav>
    </header>
  );
};

export default Header;
