import React from 'react';

// Component imports
import { Link } from 'gatsby';
import { Text } from '@utils';
import { StaticImage } from 'gatsby-plugin-image';

// Components
const FooterLink: React.FC<{ href: string }> = ({ children, href }) => (
  <Link
    className="hover:text-coral focus:outline-none focus:text-coral text-lg transition duration-200 ease-out"
    to={href}
  >
    <Text>{children}</Text>
  </Link>
);

const Footer: React.FC = () => {
  return (
    <footer className="bg-gray-700">
      <div className="sm:p-6 md:p-8 lg:py-16 md:flex-col md:flex xl:space-x-32 xl:space-y-0 xl:flex-row md:space-y-0 md:space-y-16 container p-4 py-8 mx-auto space-y-8">
        <div className="md:text-center xl:w-1/3 xl:text-left md:w-full md:space-y-3 text-center">
          <StaticImage
            src="../../assets/img/logo.png"
            alt="Coral Dev"
            placeholder="tracedSVG"
            className="md:w-64 object-contain w-40"
          />
          <Text className="xl:w-8/12">
            The premier Minecraft plugins development team.
          </Text>
        </div>
        <div className="md:w-full xl:w-2/3 md:flex md:flex-col space-y-10">
          <div className="md:flex md:space-y-0 md:space-x-5 space-y-5">
            <div className="md:text-left md:w-1/3 md:items-start flex flex-col items-center space-y-4 text-center">
              <Text style="h4" tag="p" className="text-coral">
                Quick Links
              </Text>
              <ul className="space-y-2">
                <li>
                  <FooterLink href="/">Home</FooterLink>
                </li>
                <li>
                  <FooterLink href="/team">Our Team</FooterLink>
                </li>
                <li>
                  <FooterLink href="/work">Our Work</FooterLink>
                </li>
                <li>
                  <FooterLink href="/blog">Blog</FooterLink>
                </li>
                <li>
                  <FooterLink href="/">Contact</FooterLink>
                </li>
              </ul>
            </div>
            <div className="md:text-left md:w-1/3 md:items-start flex flex-col items-center space-y-4 text-center">
              <Text style="h4" tag="p" className="text-coral">
                Legal
              </Text>
              <ul className="space-y-2">
                <li>
                  <FooterLink href="/terms">Terms of Service</FooterLink>
                </li>
                <li>
                  <FooterLink href="/privacy">Privacy Policy</FooterLink>
                </li>
              </ul>
            </div>
            <div className="md:w-1/3 md:text-left md:items-start flex flex-col items-center space-y-4 text-center">
              <Text style="h4" tag="p" className="text-coral">
                Company Information
              </Text>
              <Text>2035 Sunset Lake Rd. , Suite B-2, Newark, DE 19702</Text>
              <a
                href="mailto:hello@coraldev.net"
                className="focus:outline-none focus:text-coral hover:text-coral ease-outQuint transition duration-500"
              >
                <Text>hello@coraldev.net</Text>
              </a>
            </div>
          </div>
          <div>
            <p className="xl:text-right xl:text-sm text-xs text-center">
              &copy; {new Date().getFullYear()} Syncore LLC. All Rights
              Reserved.
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
