import React from 'react';

// Component imports
import Header from '@global/Header';
import Footer from '@global/Footer';
import { LazyMotion, domAnimation } from 'framer-motion';
import { JsonLd } from 'gatsby-plugin-next-seo';

// Styles
import '@fontsource/barlow/400.css';
import '@fontsource/barlow/500.css';
import '@fontsource/barlow/600.css';
import '@fontsource/barlow/700.css';
import '../../styles/tailwind.css';

const Layout: React.FC = ({ children }) => {
  return (
    <>
      <JsonLd
        json={{
          '@context': 'https://schema.org',
          '@type': 'Corporation',
          name: 'Coral Dev',
          alternateName: 'Syncore LLC',
          url: 'https://coraldev.net',
          logo: 'https://img.syn-core.com/coraldev/logos/glyph.png',
          sameAs: [
            'https://github.com/Coral-Development',
            'https://coraldev.net',
          ],
        }}
      ></JsonLd>

      <LazyMotion features={domAnimation}>
        <div className="font-body flex flex-col min-h-screen text-white bg-gray-900">
          <Header />
          <main className="flex flex-col flex-1 -mt-10">{children}</main>

          <Footer />
        </div>
      </LazyMotion>
    </>
  );
};

export default Layout;
