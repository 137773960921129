module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Coral Dev","short_name":"Coral Dev","start_url":"/","background_color":"#1A1A1A","theme_color":"#FF7E50","display":"standalone","icon":"src/assets/img/icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"53224f15a377098b9ec7a784d28c40c4"},
    },{
      plugin: require('../node_modules/gatsby-plugin-next-seo/gatsby-browser.js'),
      options: {"plugins":[],"titleTemplate":"%s | Coral Dev","openGraph":{"type":"website","locale":"en_IE","site_name":"Coral Dev","img":{"url":"/thumbnail.png","alt":"Coral Dev - the premier Minecraft plugins development team."}},"twitter":{"handle":"@CoralDevTeam","site":"@CoralDevTeam","cardType":"summary_large_image"}},
    },{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[],"component":"/Users/shaun/Desktop/lab/coraldev-website/src/components/globals/Layout.tsx"},
    }]
