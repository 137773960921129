import React from 'react';

interface Props {
  id: string;
  children: React.ReactElement;
  className?: string;
}

const Section = ({ id, children, className = '' }: Props) => {
  return (
    <section
      id={id}
      className={`container mx-auto p-4 sm:px-6 md:px-8 py-16 xl:py-20 ${className}`}
    >
      {children}
    </section>
  );
};

export default Section;
