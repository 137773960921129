import React from 'react';

interface Props {
  children: any;
  style?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'p';
  tag?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'p';
  className?: string;
}

const Text = ({ children, style = 'p', tag = 'p', className = '' }: Props) => {
  return React.createElement(
    tag,
    { className: `${style} ${className}` },
    children
  );
};

export default Text;
