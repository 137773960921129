import React from 'react';

// Component imports
import { Link } from 'gatsby';

interface Props {
  type: 'internal' | 'external' | 'button';
  variant: 'primary' | 'secondary';
  children: string;
  href?: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
}

const Button = ({ type, variant, children, href, ...props }: Props) => {
  switch (type) {
    case 'internal': {
      return (
        // @ts-ignore
        <Link to={href as string} className={variant} {...props}>
          {children}
        </Link>
      );
    }
    case 'external': {
      return (
        <a className={variant} rel="noopener noreferrer" href={href}>
          {children}
        </a>
      );
    }
    case 'button': {
      return (
        <button {...props} className={variant}>
          {children}
        </button>
      );
    }
  }
};

export default Button;
